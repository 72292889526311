import React from 'react';
import Typography from '@mui/material/Typography';
import theme from '@wavetrak/theme';
import { formatInTimeZone } from 'date-fns-tz';
import styles from './WaveInfoBar.module.scss';
import RatingsCell from '../../../../components/TableCell/RatingsCell/RatingsCell';

export interface WaveInfoBarProps {
  waveSize?: number;
  surfedDuration?: number;
  rating?: string;
  time?: string | null;
  timezone?: string | null;
}

const WaveInfoBar: React.FC<WaveInfoBarProps> = ({
  waveSize,
  surfedDuration,
  rating,
  time,
  timezone,
}) => (
  <div className={styles.waveInfoBar}>
    {time && (
      <Typography variant="body2" color={theme.palette.common.white} className={styles.timeInfo}>
        {time && timezone && formatInTimeZone(time, timezone, "hh:mmaaaaa'm'")}
      </Typography>
    )}
    <div className={styles.waveInfoContent}>
      <div className={styles.infoItem}>
        <Typography variant="caption1" color={theme.palette.common.white}>
          Wave Size
        </Typography>
        <Typography variant="body2" color={theme.palette.common.white}>
          {waveSize ? `${waveSize?.toFixed(1)}ft` : 'n/a'}
        </Typography>
      </div>
      <div className={styles.infoItem}>
        <Typography variant="caption1" color={theme.palette.common.white}>
          Surfed Duration
        </Typography>
        <Typography variant="body2" color={theme.palette.common.white}>
          {surfedDuration ? `${surfedDuration?.toFixed(1)}s` : 'n/a'}
        </Typography>
      </div>
      <div className={styles.infoItem}>
        <Typography variant="caption1" color={theme.palette.common.white}>
          Rating
        </Typography>
        <RatingsCell rating={rating} loading={!rating} text />
      </div>
    </div>
  </div>
);

export default WaveInfoBar;
