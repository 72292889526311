import IconButton from '@mui/material/IconButton';

import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'components/Icons';
import VideoPlaylistPlayer from 'components/VideoPlaylistPlayer';

import WaveInfoBar from '../WaveInfoBar/WaveInfoBar';
import styles from './ReelPlayer.module.scss';

// TODO - populate this
type Props = any;

const ReelPlayer = ({ clips, currentClipIndex, setCurrentClipIndex, setCurrentClip }: Props) => (
  <div className={styles.playerContainer}>
    <IconButton
      disableRipple
      className={styles.skipButton}
      onClick={() => {
        setCurrentClipIndex(currentClipIndex - 1);
      }}
      disabled={currentClipIndex <= 0}
    >
      <ChevronLeft />
    </IconButton>
    <div className={styles.playerOverlayContainer}>
      <div className={styles.clipIndicatorContainer}>
        {clips.map((clip: any, index: number) => (
          <div
            key={clip.startTime}
            className={classNames(
              styles.clipIndicator,
              currentClipIndex >= index && styles.activeClip,
            )}
          />
        ))}
      </div>
      <VideoPlaylistPlayer
        playlist={clips.map((clip: any) => clip.url) as string[]}
        currentPlayer={currentClipIndex}
        setCurrentPlayer={setCurrentClip}
      />
      {clips && (
        <WaveInfoBar
          waveSize={clips[currentClipIndex]?.surfHeight}
          surfedDuration={clips[currentClipIndex]?.surfedDuration}
          rating={clips[currentClipIndex]?.ratingKey}
          time={clips[currentClipIndex]?.startTime}
          timezone={clips[currentClipIndex]?.timezone}
        />
      )}
    </div>
    <IconButton
      disableRipple
      className={styles.skipButton}
      onClick={() => {
        setCurrentClipIndex(currentClipIndex + 1);
      }}
      disabled={currentClipIndex >= clips.length - 1}
    >
      <ChevronRight />
    </IconButton>
  </div>
);

export default ReelPlayer;
