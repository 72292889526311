import React, { useEffect, useMemo, useState } from 'react';
import { Cookies } from 'react-cookie';
import createClip from 'components/CamPlayerKbygClipRewinds/utils/requests';
import { formatInTimeZone } from 'date-fns-tz';
import ButtonContainer from 'containers/RewindRecapsPoc/components/ButtonContainer/ButtonContainer';
import ReelPlayer from 'containers/RewindRecapsPoc/components/ReelPlayer/ReelPlayer';
import ArrowRightAltRounded from '@mui/icons-material/ArrowRightAltRounded';
import theme from '@wavetrak/theme';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useRouter } from 'next/router';
import styles from './RegionalRewindRecapsPoc.module.scss';

const DEFAULT_RECAPS_VERSION = 'v6';

const fetchClip =
  (accessToken: string) =>
  async ({ startTime, endTime, waveHeight, camId, timezone, ratingKey, surfedDuration }: any) => {
    let url = '';

    const start = startTime * 1000;
    let end = endTime * 1000;

    if (end - start < 3000) {
      end = start + 5000;
    }

    try {
      const response = await createClip(camId, start, end, accessToken);

      url = response.data.clipUrl;
    } catch (error) {
      console.error('Error creating clip', error);
    }

    return {
      url,
      label: '',
      startTime: start,
      surfHeight: waveHeight,
      ratingKey,
      surfedDuration,
      timezone,
    };
  };

const fetchClips = async (accessToken: string, waves: any[]) =>
  Promise.all(waves.map(fetchClip(accessToken)));

const fetchRegionalData = async (recapsVersion?: string | string[]) => {
  const today = formatInTimeZone(Date.now(), '-09:00', 'yyyy-MM-dd');
  const versionSuffix = recapsVersion ? `_${recapsVersion}` : '';
  const regionalDataResponse = await fetch(
    // probably not worth updating the config etc to pull this from an env var for the POC
    // sandbox and prod both use the same data cos snowflake doesn't have environments
    `https://rewind-recap-generator-poc.sandbox.surfline.com/${today}_recaps_manifest${versionSuffix}.json`,
  );
  return regionalDataResponse?.json();
};

const RegionalRewindRecapsPoc = () => {
  const cookie = new Cookies();
  const accessToken = cookie.get('access_token');
  const router = useRouter();
  const { recapsVersion } = router.query;

  const [generatedClips, setGeneratedClips] = useState<any>(null);
  const [regionalData, setRegionalData] = useState<any>(null);
  const [currentRegion, setCurrentRegion] = useState<any>(null);

  useEffect(() => {
    fetchRegionalData(recapsVersion || DEFAULT_RECAPS_VERSION).then((data) => {
      setRegionalData(data);
      setCurrentRegion(Object.keys(data)[0]);
    });
  }, [recapsVersion]);

  const waves = useMemo(() => {
    if (currentRegion && regionalData) {
      return regionalData[currentRegion];
    }
    return null;
  }, [currentRegion, regionalData]);

  useEffect(() => {
    if (accessToken && waves) {
      fetchClips(accessToken, waves).then((fetchedClips: any) => setGeneratedClips(fetchedClips));
    }
  }, [accessToken, waves]);

  const [currentClipIndex, setCurrentClipIndex] = useState(0);

  return (
    <div className={styles.pageContainer}>
      <Accordion className={styles.accordion} defaultExpanded>
        <AccordionSummary expandIcon={<ArrowDropDownIcon className={styles.expandIcon} />}>
          <Typography
            variant="title3"
            color={theme.palette.common.white}
            className={styles.regionalHeader}
          >
            Rewind Recaps: Best waves yesterday
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          {regionalData && (
            <ButtonContainer
              onButtonClick={(index: number) => {
                setCurrentRegion(Object.keys(regionalData)[index]);
                setCurrentClipIndex(0);
                setGeneratedClips(null);
              }}
              buttons={Object.keys(regionalData).map((region) => region.replace(/_/g, ' '))}
              currentButton={Object.keys(regionalData).indexOf(currentRegion)}
            />
          )}
          <div className={styles.regionalHeaderWrapper}>
            <Typography
              variant="title3"
              color={theme.palette.common.white}
              className={styles.header}
            >
              {waves?.[currentClipIndex]?.spotName}
            </Typography>
            {waves?.[currentClipIndex]?.spotId && (
              <a
                href={`/rewind-recaps?spotId=${waves?.[currentClipIndex]?.spotId}`}
                className={styles.seeMoreButton}
              >
                <Typography variant="caption1" className={styles.seeMoreButton}>
                  See More <ArrowRightAltRounded />
                </Typography>
              </a>
            )}
          </div>
          {generatedClips && (
            <ReelPlayer
              clips={generatedClips}
              currentClipIndex={currentClipIndex}
              setCurrentClipIndex={setCurrentClipIndex}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RegionalRewindRecapsPoc;
